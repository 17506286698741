<template>
  <div>
    <!-- <label class="switch">
      <input type="checkbox" @click="popupActive=true" :checked="value == '1'" />
      <span class="slider round"></span>
    </label>-->
    <!-- {{ params.data.department_active }} -->
    <!-- {{ form.department_active }}
    <div class="vx-col sm:w-1/2 w-full">
      <vs-switch false-value="2" true-value="1" v-model="form.department_active" />
    </div>-->

    <b-form-checkbox
      @click.native="popupActive = true"
      value="1"
      unchecked-value="2"
      v-model="form.location_active"
      switch
      class="table-status-icon"
    ></b-form-checkbox>

    <div class="demo-alignment">
      <vs-popup
        :id="'popmodalS' + params.data.location_id"
        :button-close-hidden="true"
        class="holamundo p-2"
        title="Location Status"
        :active.sync="popupActive"
      >
        <div class="text-center">
          <h5>Do you want to change Status ?</h5>
          <!-- SUBMIT BUTTON -->
          <div class="vx-row mt-5">
            <div class="vx-col mx-auto">
              <!-- :disabled="submitStatus" -->
              <vs-button
                color="success"
                type="filled"
                @click="onChangeCheckbox($event)"
                class="mr-3"
                >Yes</vs-button
              >

              <vs-button color="danger" type="filled" @click="privStatus()"
                >No</vs-button
              >
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
  <!--  -->
</template>

<script>
import Vue from "vue";
import shootLocationService from "@/services/shootLocationService.js";

export default Vue.extend({
  data() {
    return {
      params: null,
      popupActive: false,
      form: {
        location_active: "1",
      },
      copy_location_active: null,
    };
  },
  beforeMount() {
    this.form = this.params.data;
    console.log(this.form);
    this.copy_location_active = this.form.location_active;
    console.log(this.copy_location_active, "iuyu");
  },
  beforeDestroy() {
    let _id = document.getElementById(
      "popmodalS" + this.params.data.location_id
    );
    console.log("_id", _id);
    _id.setAttribute("style", "display: none;");
  },
  methods: {
    onChangeCheckbox: function (e, value) {
      this.$vs.loading();
      let id = this.params.data.location_id;
      // let c_id = this.params.data.company_id;
      // console.log("c_idSSSSS :", c_id);
      let payload = {
        location_active: this.form.location_active,
        location_name: this.form.location_name,
        // company_id: c_id,
      };
      shootLocationService
        .editShootLocation(payload, id)
        .then((response) => {
          this.$vs.loading.close();
          const { data } = response;
          if (data.Status == true || data.Status == "true") {
            this.$vs.notify({
              title: "Updated!",
              text: data.Message,

              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
            this.popupActive = false;
          } else {
            this.$vs.loading.close();
            console.log("data :", data);
            this.$vs.notify({
              title: "Error!",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
            this.popupActive = false;
            this.form.location_active = this.copy_location_active;
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.popupActive = false;
          this.form.location_active = this.copy_location_active;
        });
    },
    privStatus: function () {
      this.popupActive = false;
      this.form.location_active = this.copy_location_active;
    },
  },
});

// import Vue from "vue";

// export default Vue.extend({
//   template: ` {{params.value}}
//          <label class="switch">
//           <input @click="onChangeCheckbox" type="checkbox">
//           <span class="slider round"></span>
//     </label> `,
//   data: function () {
//     return {};
//   },
//   beforeMount() {},
//   mounted() {},
//   methods: {

//   }
// });
</script>
